import MatrixPlot from "./MatrixPlot";

function FeatureMatrix({ feat_mat, classes, feat_names, figureTitle, selectedRegions, setSelectedRegions }) { 
    const figuresDiv = document.getElementsByClassName('figures-container')[0];
    const width = (figuresDiv.clientWidth);
    const height = width / 2;
  
    const onClickAction = ({i, j}) => {
      // this currently is hard-wired to select brain region on MRIViewer even if the set of features / columns is not from structure_region (e.g. structure_autoencoder)
      setSelectedRegions([j]);
    };
  
    return (
      <>
        <div className="feature-matrix mr-10">
          <MatrixPlot 
            matrix={feat_mat}
            figureTitle={figureTitle}
            rowLabels={classes}
            columnLabels={feat_names}
            width={width}
            height={height}
            onClickAction={onClickAction}
            showValues={false}
            setColorscale={'bwr'}
            axisNames={{'x': 'Feature', 'y': 'Class'}}
            marginScales={{'top': 0.05, 'right': 0.04, 'bottom': 0.05, 'left': 0.05}}
            selectedRegions={selectedRegions}
            setSelectedRegions={setSelectedRegions}
          />
        </div>
      </>
    );
  }

  export default FeatureMatrix;