import ConfusionMatrix from "./ConfusionMatrix";
import ROCCurve from './ROCCurve'
import FeatureMatrix from './FeatureMatrix'
import RegressionPlot from "./RegressionPlot";



function Figures({isLoading, dataGlobal, selectedData, statsModels, statsResults, selectedSingleIndex, setSelectedSingleIndex, setSelectedGroup, categoryColors, selectedRegions, setSelectedRegions, selectedGroup, brainRegionInfo }) {
  

    function classificationLayout(model) {
      return (
        <div className="model-container">
          <h3 className="text-lg font-semibold mb-4">
            {model.type} Model<br />
            <span>Train accuracy: {(model.accuracy.train_accuracy_tot * 100).toFixed(1) + '%'} | </span>
              <span>Test accuracy: {(model.accuracy.test_accuracy_tot * 100).toFixed(1) + '%'}</span><br />
              <span>Valid data: {model.model_params.valid_train_ids_count[0]} / {model.model_params.valid_train_ids_count[1]} train scans, {model.model_params.valid_test_ids_count[0]} / {model.model_params.valid_test_ids_count[1]} test scans </span>
          </h3>
          <div class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <div class="w-full md:w-1/2">
              <ConfusionMatrix 
                confusionMatrixData={model.confusionMatrixData.train_freq}
                confusionMatrixIndices={model.confusionMatrixData.train_indices}
                classes={model.classes}
                figureTitle={'Train Confusion Matrix'} 
                setSelectedGroup={setSelectedGroup}
                setSelectedSingleIndex={setSelectedSingleIndex}
              />
            </div>
            <div class="w-full md:w-1/2">
              <ROCCurve 
                model={model} 
                model_type='train'
                figureTitle={'Train ROC Curves'}
                className={model.model_params.response_variable_names[0]}
                curveColors={categoryColors}
                setSelectedGroup={setSelectedGroup}
                dataGlobal={dataGlobal}
              />
            </div>
          </div>
          <div class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <div class="w-full md:w-1/2">
              <ConfusionMatrix 
                confusionMatrixData={model.confusionMatrixData.test_freq}
                confusionMatrixIndices={model.confusionMatrixData.test_indices}
                classes={model.classes} 
                figureTitle={'Test Confusion Matrix'} 
                setSelectedGroup={setSelectedGroup}
                setSelectedSingleIndex={setSelectedSingleIndex}
              />
            </div>
            <div class="w-full md:w-1/2">
              <ROCCurve 
                model={model} 
                model_type='test'
                figureTitle={'Test ROC Curves'}
                className={model.model_params.response_variable_names[0]}
                curveColors={categoryColors}
                setSelectedGroup={setSelectedGroup}
                dataGlobal={dataGlobal}
              />
            </div>
          </div>
          <div className="mx-auto flex flex-row space-x-4 mt-4 ">
            <FeatureMatrix
              feat_mat={model.features_mat}
              classes={model.classes}
              feat_names={model.model_params.predictor_variable_names}
              figureTitle={'Feature weights'}
              selectedRegions={selectedRegions}
              setSelectedRegions={setSelectedRegions}
            />
            {/* Placeholder for future file list */}
            {/* <div>Feature weights file list here</div> */}
          </div>
          {/* <hr style={{ width: '98%', margin: '10px auto', height: 4, backgroundColor: 'black' }}/> */}
      </div>
      );
    };
  
    function regressionLayout(model) {
      //const feat_array = model.modelCoefficients.slice(1).map((item, index) => index);
      //const feat_names = feat_array.join(",");
      return (
        <div className="model-container">
          <h3 className="text-lg font-semibold mb-4">
            {model.type} Model<br />
            <span>Train accuracy: r<sup>2</sup>={(model.modelAccuracy.train_model_rsquared).toFixed(3)} | </span>
            <span>Test accuracy: r<sup>2</sup>={(model.modelAccuracy.test_model_rsquared).toFixed(3)}</span><br />
            <span>Valid data: {model.modelParameters.valid_train_ids_count[0]} / {model.modelParameters.valid_train_ids_count[1]} train scans, {model.modelParameters.valid_test_ids_count[0]} / {model.modelParameters.valid_test_ids_count[1]} test scans </span>
          </h3>
          <div>
            <RegressionPlot 
              model={model} 
              model_type='train'
              figureTitle={'Train Regression'}
              setSelectedSingleIndex={setSelectedSingleIndex}
              dataGlobal={dataGlobal}
            />
          </div>
          <div>
            <RegressionPlot 
              model={model} 
              model_type='test'
              figureTitle={'Test Regression'}
              setSelectedSingleIndex={setSelectedSingleIndex}
              dataGlobal={dataGlobal}
            />
          </div>
          <div>
            <FeatureMatrix
              feat_mat={[model.modelCoefficients.slice(1)]}
              classes={model.modelParameters.response_variable_names}
              feat_names={model.modelParameters.predictor_variable_names}
              //feat_names={feat_names}
              figureTitle={'Feature weights'}
              selectedRegions={selectedRegions}
              setSelectedRegions={setSelectedRegions}
            />
          </div>
  
          <hr style={{ width: '98%', margin: '10px auto', height: 4, backgroundColor: 'black' }}/>
        </div>
      );
    };
  
    function distributionLayout(model) {
      return(
        <div className="model-container">
          <h3>Distribution Plots</h3>
          {model.variables.map((variableName, index) => (
            <div key={index}>
              <p>
                <DistributionPlot 
                  dataGlobal={dataGlobal}
                  selectedData={selectedData}
                  variableName={variableName}
                />
              </p>
            </div>
          ))}
          <hr style={{ width: '98%', margin: '10px auto', height: 4, backgroundColor: 'black' }}/>
        </div>
      );
    };
  
  
    function brainAgeLayout(model) {
      //const feat_array = model.modelCoefficients.slice(1).map((item, index) => index);
      //const feat_names = feat_array.join(",");
      const currentRegion = model.modelResults.effect_plots.filter(regionEffect => (regionEffect.region=='S100'))[0];
      return (
        <div className="model-container">
          <h3 className="text-lg font-semibold mb-4">
            Brain Age Model<br />
            <span>Train accuracy: r<sup>2</sup>={(model.modelAccuracy.train_model_rsquared).toFixed(3)} | </span>
            <span>Test accuracy: r<sup>2</sup>={(model.modelAccuracy.test_model_rsquared).toFixed(3)}</span>
            <br />
            <span>Train MAE: &plusmn;{(model.modelAccuracy.train_mae).toFixed(2)} yrs | </span>
            <span>Test MAE: &plusmn;{(model.modelAccuracy.test_mae).toFixed(2)} yrs</span>
          </h3>
          <div>
            <RegressionPlot 
              model={model} 
              model_type='train'
              figureTitle={'Train Model'}
              setSelectedSingleIndex={setSelectedSingleIndex}
              dataGlobal={dataGlobal}
            />
          </div>
          <div>
            <RegressionPlot 
              model={model} 
              model_type='test'
              figureTitle={'Test Model'}
              setSelectedSingleIndex={setSelectedSingleIndex}
              dataGlobal={dataGlobal}
            />
          </div>
          <div>
            <EffectPlot 
              model={model} 
              model_type='test'
              figureTitle={'Region Effect Plot'}
              selectedSingleIndex={selectedSingleIndex}
              setSelectedSingleIndex={setSelectedSingleIndex}
              dataGlobal={dataGlobal}
              selectedRegions={selectedRegions}
              setSelectedRegions={setSelectedRegions}
              selectedGroup={selectedGroup}
              brainRegionInfo={brainRegionInfo}
            />
          </div>
  
          <hr style={{ width: '98%', margin: '10px auto', height: 4, backgroundColor: 'black' }}/>
        </div>
      );
    };
  
  
    return (
      <div className="figures-container">
        {isLoading ? (
          // When loading, display the thinking text
          <div className="font-bold m-0 text-2xl p-3 bg-clip-text text-transparent bg-gradient-to-r from-gray-400 via-black to-gray-400 bg-[length:200%_100%] animate-wave">Results</div>
        ) : (
          // When not loading, display the component header and the results
          <>
            <div className="font-bold m-0 text-2xl p-3">Results</div>
        {statsModels.slice(0,3).map((model, index) => {
          // Assign a unique key prop to each layout function call
          switch (model.type) {
            case 'Classification':
              return <div key={`classification-${index}`}>{classificationLayout(model)}</div>;
            case 'Regression':
              return <div key={`regression-${index}`}>{regressionLayout(model)}</div>;
            case 'Distribution':
              return <div key={`distribution-${index}`}>{distributionLayout(model)}</div>;
            case 'Custom':
              return <div key={`brainAge-${index}`}>{brainAgeLayout(model)}</div>;
            default:
              return null;
          }
        })}
        </>
        )}
      </div>
    );
  }

  export default Figures;
  