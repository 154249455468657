// NavBar.js
import { useUser, useConfig } from './auth';
import { useLocation, Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { ClipboardDocumentIcon } from '@heroicons/react/24/solid';


function NavBarItem(props) {
  const location = useLocation();
  const isActive =
    (props.href && location.pathname.startsWith(props.href)) ||
    (props.to && location.pathname.startsWith(props.to));
  const baseClasses = 'px-4 py-2 hover:text-teal-green';
  const activeClasses = isActive ? 'text-teal-green font-semibold' : 'text-black';

  return (
    <li>
      {props.href ? (
        <a className={`${baseClasses} ${activeClasses}`} href={props.href}>
          {props.icon} {props.name}
        </a>
      ) : (
        <Link className={`${baseClasses} ${activeClasses}`} to={props.to}>
          {props.icon} {props.name}
        </Link>
      )}
    </li>
  );
}

function Dropdown({ label, items, align='left' }) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className="px-4 py-2 text-black hover:text-teal-green inline-flex items-center"
        onClick={() => setOpen(!open)}
      >
        {label}
        <svg
          className={`ml-1 h-4 w-4 transition-transform ${
            open ? 'transform rotate-180' : ''
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
          stroke="currentColor"
        >
          <path d="M7 7l3 6 3-6H7z" />
        </svg>
      </button>

      {open && (
        <div
        className={`absolute ${align === 'right' ? 'right-0' : 'left-0'} mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10`}
        >
          {items.map((item, index) => {
            const isExternal = item.to.startsWith('http');
            return isExternal ? (
              <a
                key={index}
                href={item.to}
                target="_blank"
                rel="noopener noreferrer"
                className="block px-4 py-2 text-black hover:bg-gray-100 hover:text-teal-green"
                onClick={() => setOpen(false)}
              >
                {item.name}
              </a>
            ) : (
              <Link
                key={index}
                to={item.to}
                className="block px-4 py-2 text-black hover:bg-gray-100 hover:text-teal-green"
                onClick={() => setOpen(false)}
              >
                {item.name}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
}


export default function NavBar() {
  const user = useUser();
  const config = useConfig();

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCopyEmail = () => {
    navigator.clipboard.writeText('contact@radiata.ai');
    setCopied(true);
    setTooltipVisible(true);
    setTimeout(() => {
      setCopied(false);
      setTooltipVisible(false);
    }, 2000);
  };

  const anonNav = (
    <>
      <NavBarItem to='/account/signup' name='Process scans' />
      <Dropdown
        label="Browse Datasets"
        align="left"
        items={[
          { to: 'public-studies', name: 'Public Studies' },
        ]}
      />
      <NavBarItem to="/account/login" name="Log in" />
      <NavBarItem to="/account/signup" name="Create account" />
    </>
  );

  const authNavLeft = (
    <>
      <NavBarItem to='/about' name='About' />
      <li className="relative">
        <span
          className="px-4 py-2 text-black hover:text-teal-green cursor-pointer"
          onClick={handleCopyEmail}
          onMouseEnter={() => !copied && setTooltipVisible(true)}
          onMouseLeave={() => !copied && setTooltipVisible(false)}
        >
          contact@radiata.ai
        </span>
        {tooltipVisible && (
        <div className="absolute top-full left-0 mt-1 bg-gray-700 text-white text-base rounded px-2 py-1 flex items-center">
        {!copied && <ClipboardDocumentIcon className="h-4 w-4 mr-2" />}
        <span>{copied ? '✓ Copied' : 'Copy email'}</span>
          </div>
        )}
      </li>
      <Dropdown
        label="Demos"
        align="left"
        items={[
          { to: 'https://youtu.be/EaJyo9b2W4M', name: 'Latent Space' },
          { to: 'https://youtu.be/oDansLudwc4', name: 'Classification' },
        ]}
      />
    </>
  );

  const authNav = (
    <>
      {/* <NavBarItem to='/about' name='About' /> */}
      <NavBarItem to='/account/mydata/upload' name='Process Scans' />

      <Dropdown
        label="Browse Datasets"
        align="left"
        items={[
          { to: '/account/mydata/public-studies', name: 'Public Studies' },
          { to: '/account/mydata/my-studies', name: 'My Studies' },
          { to: '/account/mydata/upload', name: 'Upload Data' },
        ]}
      />

      <Dropdown
        label="Account"
        align="right"
        items={[
          { to: '/account/password/change', name: 'Change Password' },
          { to: '/account/email', name: 'Change Email' },
          { to: '/dashboard/account/logout', name: 'Log out' }
        ]}
      />
    </>
  );

  const [imageSrc, setImageSrc] = useState('/images/radiata_logo_gray.png');

  const handleMouseOver = () => {
    setImageSrc('/images/radiata_logo_glow.png');
  };

  const handleMouseOut = () => {
    setImageSrc('/images/radiata_logo_gray.png');
  };

  return (
    <nav className="flex items-center justify-between px-4 py-2 bg-custom-gray shadow-md flex-none">
      {/* Left Navigation */}
      <div className="flex-1">
        <ul className="flex items-center space-x-4">
          {/* {user ? authNavLeft : null} */}
          {authNavLeft}
        </ul>
      </div>

      {/* Center Logo */}
      {/* <Link to="/dashboard" className="flex-none"> */}
      <Link to={user ? '/dashboard' : '/'} className="flex-none">
        <img
          src={imageSrc}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          width="200"
          alt="Logo"
          className="mx-auto"
        />
      </Link>

      {/* Right Navigation */}
      <ul className="navbar-nav flex-1 flex justify-end items-center space-x-4">
        {user ? authNav : anonNav}
      </ul>
    </nav>
  );
}

